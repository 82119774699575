<template>
  <ion-grid class="results">
    <ion-row class="ion-justify-content-center">
      <ion-col size="7">
        <img :src="user.avatar_url" />
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col size="auto">
        <h2>{{ user.nome }}</h2>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col v-if="this.success">
        <h4>Complimenti! Hai superato il test!</h4>
      </ion-col>
      <ion-col v-else
        ><h4>
          Non hai raggiunto il numero di risposte giuste per accedere al blocco
          successivo, riprova
        </h4></ion-col
      >
    </ion-row>
    <ion-row class="ion-justify-content-center border-radius">
      <ion-col size="auto">
        <ion-button
          :href="
            'campaign/learning/' +
            $route.params.parentId +
            '/' +
            $route.params.parentTitle.replace(/\s/g, '%20') +
            '/' +
            $route.params.parent
          "
          @click="closeModal"
          >Close</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { modalController, IonGrid, IonRow, IonCol } from "@ionic/vue"
import ApiService from "../../common/service.api"

export default {
  name: "ModalQuizResult",
  props: {
    score: Number,
    maxScore: Number,
    user: Object,
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      success: false,
    }
  },
  created() {
    this.score / this.maxScore > 0.59
      ? (this.success = true)
      : (this.success = false)

    console.log(this.$route.params.parentId)
  },
  methods: {
    closeModal() {
      //       this.$router.replace(
      //         "campaign/learning/" +
      //           this.$route.params.parentId +
      //           "/" +
      //           this.$route.params.parentTitle.replace(/\s/g, "%20") +
      //           "/" +
      //           this.$route.params.parent
      //         //   params: {
      //         //     id: this.$route.params.parentId,
      //         //     parent: this.$route.params.parent,
      //         //     title: this.$route.params.parentTitle,
      //         //   },
      //       );
      // return modalController.dismiss();
    },
  },
}
</script>

<style scoped>
.results {
  width: 100%;
  background: var(--ion-color-primary);
  color: var(--text-color-light);
  padding: 2rem 1rem;
  text-align: center;
}

ion-button {
  margin-top: 2rem;
}

ion-button::part(native) {
  background: var(--ion-color-secondary);
  --border-radius: var(--ion-border-radius);
}

img {
  border-radius: 50%;
}
</style>
